import React, { FunctionComponent, useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import { SEO } from '../components/SEO';
import { Intro } from '../components/Intro';

import { StandardBlock } from '../components/StandardBlock';
import { AccentBlock } from '../components/AccentBlock';
import { FontSize } from '../type';
import { EffectsSupported } from '../lib/effects';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Contact from '../components/contact/Contact';
import styled from 'styled-components';
import { maxWidth, media, paddingLg, withTheme } from '../style';
import { align } from '@bucket-of-bolts/styled-companion/build';
import LanguageModal from '../components/LanguageModal';
// @ts-ignore
import { useQueryParam } from 'gatsby-query-params';
import { keywords } from '../locales/keywords';
import { AboutBlock } from '../components/AboutBlock';

interface Graphics {
    source?: string;
    author?: string;
    image: any;
}

interface Node {
    id: string;
    html: string;
    frontmatter: {
        title?: string;
        id?: string;
        lang?: string;
        graphics: Graphics[];
        widget: 'StandardBlock' | 'AccentBlock' | 'QuoteBlock' | 'AboutBlock';
        fontSize: FontSize;
        effect?: EffectsSupported;
        effectTimeout?: number;
    };
}

interface Data {
    allMarkdownRemark: {
        nodes: Node[];
    };
}

interface Props {
    data: Data;
}

const getWidget = (node: Node) => {
    const { frontmatter: { widget = '' } = {} } = node;
    if (widget === 'AccentBlock') {
        return AccentBlock;
    }
    if (widget === 'AboutBlock') {
        return AboutBlock;
    }

    return StandardBlock;
};

const NodeContainer = styled.div`
    ${media({ xs: 'margin: 2rem 0.4rem;' })}
`;
const Content = withTheme(styled.div`
    max-width: ${maxWidth};
    margin: 2rem 1rem;
    ${media({ xs: 'h1 { font-size: 1.4rem}', lg: paddingLg })}
    ${media({ xs: 'margin: 2rem 0.4rem;' })}
    //${align('center', 'center', 'column')};
    // position: relative;
    
    // @ts-ignore
    font-size: 1.1em;
`);
const HomePage: FunctionComponent<Props> = ({ data }) => {
    const { allMarkdownRemark: { nodes = [] } = {} } = data;
    const { ready, i18n, t, language } = useI18next('translation', {
        useSuspense: false,
    });
    // const { t, i18n, ready } = useTranslation('translation', {
    //     useSuspense: false,
    // });
    const hideLang = !!useQueryParam('hideLanguageModal');
    useEffect(() => setFirstLoadModal(!hideLang), [hideLang]);
    const [firstLoadModal, setFirstLoadModal] = useState(!hideLang);
    if (!ready) return <div>Loading</div>;
    return (
        <>
            <SEO
                lang={language}
                siteTitle={
                    t('siteTitle') !== 'siteTitle' ? t('siteTitle') : undefined
                }
                title={'Home'}
                description={t('description')}
                keywords={keywords[language as 'nl'] as string[]}
            />
            <Intro />
            <LanguageModal
                open={firstLoadModal}
                onClose={() => setFirstLoadModal(false)}
            />
            {/*<Content>*/}
            {/*<Grid container justify={'center'}>*/}
            {/*    <Grid item xs={12} md={6} style={{textAlign: "center"}} >*/}
            {/*        <Grid container>*/}
            {/*            <Grid item xs={6} md={12}>Afspraken</Grid>*/}
            {/*            <Grid item xs={6}  md={12}>Rendez-vous</Grid>*/}
            {/*        <Grid item  xs={6} md={12}>Appointments</Grid>*/}
            {/*        <Grid item  xs={6} md={12}>Terminen</Grid>*/}
            {/*        </Grid>*/}
            {/*        </Grid>*/}
            {/*    <Grid xs={'auto'} md={6} item>*/}
            {/*        <div style={{paddingTop: '1rem'}}>email: <EmailAddress/>*/}
            {/*            <br/>*/}
            {/*            tel: <PhoneNumber/></div>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
            {/*</Content>*/}
            <NodeContainer>
                {nodes
                    .filter(
                        (a) => a.frontmatter.lang === (i18n.language ?? 'en'),
                    )
                    .map((node) => {
                        const Widget = getWidget(node);
                        return (
                            // @ts-ignore
                            <Widget
                                id={node.frontmatter.id}
                                key={node.id}
                                html={node.html}
                                {...node.frontmatter}
                            />
                        );
                    })}
            </NodeContainer>

            <Content>
                <Contact />
            </Content>
            {/*<ContactButton/>*/}
            {/*<Trans*/}
            {/*    i18nKey="urgencyDescription"*/}
            {/*    as={'a'}*/}
            {/*    t={t}*/}
            {/*    components={{ italic: <i />, bold: <strong /> }}*/}
            {/*/>*/}
            {/*<a>070 22 20 88</a>*/}
            {/*<div>*/}
            {/*    <Trans i18nKey={"covidDescription"} components={{li: <li/>, ul: <ul/>, ol: <ol/>, bold: <strong/>}}/>*/}
            {/*</div>*/}
        </>
    );
};

export const query = graphql`
    query HomePageQuery {
        allMarkdownRemark(sort: { fields: frontmatter___sort, order: ASC }) {
            nodes {
                id
                html
                frontmatter {
                    id
                    graphics {
                        image {
                            childImageSharp {
                                fluid(maxWidth: 1240, quality: 80) {
                                    ...GatsbyImageSharpFluid_tracedSVG
                                }
                            }
                        }
                        author
                        source
                        sourceText
                    }
                    title
                    widget
                    fontSize
                    lang
                    effect
                    effectTimeout
                }
            }
        }
    }
`;

export default HomePage;
