export const keywords = {
    nl: [
        'orthodontie overijse',
        'orthodontie hoeilaart',
        'orthodontist overijse',
        'orthodontist hoeilaart',
    ],
    en: [
        'orthodontics overijse',
        'orthodontics hoeilaart',
        'orthodontist overijse',
        'orthodontist hoeilaart',
    ],
    // TODO
    de: [
        'orthodontics overijse',
        'orthodontics hoeilaart',
        'orthodontist overijse',
        'orthodontist hoeilaart',
    ],
    fr: [
        'orthodontics overijse',
        'orthodontics hoeilaart',
        'orthodontist overijse',
        'orthodontist hoeilaart',
    ],
};
