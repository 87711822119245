import React, { FunctionComponent } from 'react';
import {
    AboutBlockContainer,
    AboutInner as Inner,
    AboutTitle,
    Image,
} from './style';
import { Props } from './type';
import Grid from '@material-ui/core/Grid/Grid';

const AboutBlockComponent: FunctionComponent<Props> = (props) => {
    const { html, title, fontSize = 'large', graphics = [] } = props;
    return (
        <AboutBlockContainer fontSize={fontSize} {...props}>
            <AboutTitle>{title}</AboutTitle>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Image sizes={graphics[0].image.childImageSharp.fluid} />
                </Grid>

                <Grid item xs={12} sm={8} md={8} lg={8}>
                    <Inner dangerouslySetInnerHTML={{ __html: html }} />
                </Grid>
            </Grid>
        </AboutBlockContainer>
    );
};

export const AboutBlock = AboutBlockComponent;
