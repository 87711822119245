import Grid from '@material-ui/core/Grid/Grid';
import React from 'react';
import { EmailAddress, PhoneNumber } from '../ContactInfo';
import { media, withTheme } from '../../style';
import styled from 'styled-components';
import { useI18next } from 'gatsby-plugin-react-i18next';

const FrameContainer = styled.div`
    padding: 1rem;
    iframe {
        width: 100%;
        height: 20rem;
        ${media({ sm: 'height: 20rem', xs: 'width: 100%; height: 20rem' })}
    }
`;
const Map = (props: { lang: string }) => (
    <FrameContainer>
        <iframe
            src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14935.585791900856!2d4.477845742794784!3d50.78504609529769!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3da2370046279%3A0xd7f7b2b0e81a40eb!2sKeizerlaan%2023%2C%203090%20Overijse!5e0!3m2!1s${props.lang}!2sbe!4v1605911588009!5m2!1s${props.lang}!2sbe`}
            frameBorder={0}
            style={{ border: 0 }}
            allowFullScreen={false}
            aria-hidden="false"
            tabIndex={0}
        />
    </FrameContainer>
);
const Contact = () => {
    const { t, language } = useI18next();
    return (
        <Grid container id={'contact'}>
            <Grid item xs={12} md={6}>
                <h1>{t('Contact')}</h1>
                <p>
                    {t('Keizerlaan 23')}
                    <br />
                    3090 0verijse ({t('Jezus-Eik')})<br />
                    <PhoneNumber />
                    &nbsp; ({t('preferablyContact')})<br />
                    {t('email')}:&nbsp; <EmailAddress />
                </p>
            </Grid>
            <Grid item xs={12} md={6}>
                <Map lang={language} />
            </Grid>
        </Grid>
    );
};
export default withTheme(Contact);
