import React, { FunctionComponent } from 'react';
import animateScrollTo from 'animated-scroll-to';
import {
    Container,
    BackgroundImage,
    ImageOverlay,
    Arrow,
    Data,
    Title,
    SubTitle,
    Appointments,
    AppointmentTitle,
    VertCenteredData,
} from './style';
import { Query } from './query';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { EmailAddress, PhoneNumber } from '../ContactInfo';

export const Intro: FunctionComponent<{}> = () => {
    const { t } = useTranslation();
    const scrollWindow = () => {
        const data = document.querySelector('.intro-data');
        if (data) {
            const windowScrollTop = window.scrollY || window.pageYOffset;
            const dataRect = data.getBoundingClientRect();
            animateScrollTo(dataRect.top + dataRect.height + windowScrollTop, {
                speed: 1000,
            });
        }
    };

    return (
        <Query>
            {(data) => (
                <>
                    <Container>
                        <BackgroundImage
                            sizes={data.backgroundImage.childImageSharp.fluid}
                        />
                        <ImageOverlay />
                        <Data className="intro-data">
                            <VertCenteredData>
                                <Title
                                //effect="fade-slide-left"
                                // effectTimeout={timeoutBase}
                                >
                                    Dr. Kristin van de Voorde - Heidbuchel
                                </Title>
                                <SubTitle
                                // effect="fade-slide-bottom"
                                // effectTimeout={timeoutBase + 500}
                                >
                                    {t('Orthodontics')}
                                </SubTitle>
                                <SubTitle>
                                    {t(
                                        'With over 20 years of experience in Belgium and abroad',
                                    )}
                                    <br />
                                    {t('For Children and Adults')}
                                    {/*<br/>*/}
                                    {/*<Link style={{ color: 'white', textDecoration: 'none' }} language={'nl'}*/}
                                    {/*      to={'/?hideLanguageModal=false'}>Nl</Link> – <Link*/}
                                    {/*style={{ color: 'white', textDecoration: 'none' }}*/}
                                    {/*language={'fr'} to={'/?hideLanguageModal=false'}>Fr</Link> – <Link*/}
                                    {/*style={{ color: 'white', textDecoration: 'none' }} language={'en'}*/}
                                    {/*to={'/?hideLanguageModal=false'}>Eng</Link> - <Link*/}
                                    {/*style={{ color: 'white', textDecoration: 'none' }}*/}
                                    {/*language={'de'} to={'/?hideLanguageModal=false'}>D</Link>*/}
                                </SubTitle>
                                <SubTitle
                                // effect="fade-slide-bottom"
                                // effectTimeout={timeoutBase + 500}
                                >
                                    {t('Keizerlaan 23')}, 3090 Overijse (
                                    {t('Jezus-Eik')})
                                </SubTitle>
                                <Grid
                                    container
                                    style={{
                                        marginTop: '1rem',
                                        backgroundColor: 'white',
                                        padding: '1rem',
                                        opacity: '87%',
                                        paddingTop: '1em',
                                    }}
                                >
                                    <Grid item xs={12} sm={12}>
                                        {/*<Grid container spacing={3}>*/}
                                        {/*    <Grid item>*/}
                                        {/*        <strong>Afspraak<br/>Appointment</strong>*/}
                                        {/*    </Grid>*/}
                                        {/*    <Grid item>*/}
                                        {/*        <strong>Rendez-vous<br/>Termin</strong>*/}
                                        {/*    </Grid>*/}
                                        {/*</Grid>*/}

                                        <AppointmentTitle>
                                            {t('Appointments')}
                                        </AppointmentTitle>
                                    </Grid>
                                    <Grid item>
                                        <Appointments>
                                            email: <EmailAddress />
                                            <br />
                                            tel: <PhoneNumber />
                                        </Appointments>
                                    </Grid>
                                </Grid>
                            </VertCenteredData>
                        </Data>
                        <Arrow
                            // effectTimeout={timeoutBase + 1500}
                            onClick={() => scrollWindow()}
                        />
                    </Container>
                    {/*<Copyright*/}
                    {/*    author="Caspar Camille Rubin"*/}
                    {/*    source="https://unsplash.com/photos/fPkvU7RDmCo"*/}
                    {/*    sourceText="Unsplash"*/}
                    {/*/>*/}
                </>
            )}
        </Query>
    );
};
